/**
 * 注意：当前axios封装只是初步版本，尚未定制化
 * HTTP
 * 只能通过本模块接口发起http请求
 */

// import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
// @ts-nocheck
import axios from 'zzc-axios';
import { AxiosRequestConfig, AxiosInstance } from 'axios';
import { getToken } from './cookie';
import CONVERT_CODE_MAP from '../utils/const/responseCode';
import { handleLogin } from './native';

interface RequestConfig extends AxiosRequestConfig {
    retry?: number;
    retryDelay?: number;
    useCancel?: boolean;
    extraCodeList?: any;
    extraCode?: boolean; // extraCode针对调用外部系统响应参数code与本系统不一致的问题
}



const { prefix: { PREFIX_API } } = projectConfig;

const CancelToken = axios.CancelToken;
const pending: any = {};

/**
 * 生成请求标识
 * @param {object} config
 */
const generateIdentify = (config: any) => {
    const { url, method } = config;
    const slashReg = /^(\/)/gi;
    const slash = slashReg.test(url);
    return url.includes(`${PREFIX_API}`)
        ? `${url}&${method}`
        : slash
            ? `${PREFIX_API}${url}&${method}`
            : `${PREFIX_API}/${url}&${method}`;
};

/**
 * 从队列中移除重复的请求
 * @param {string} key
 * @param {boolena} isRequest
 */
const removePending = (key: string, isRequest = false) => {
    if (pending[key] && isRequest) {
        pending[key]('取消重复请求');
        delete pending[key];
    }

};

const interceptors = {
    enctypeRequestData(config: any) {
        const { useCancel } = config;
        // 拦截重复请求(即当前正在进行的相同请求)
        let requestData = generateIdentify(config);
        useCancel && removePending(requestData, useCancel);
        // @ts-ignore
        config.cancelToken = new CancelToken((c: any) => {
            pending[requestData] = c;
        });
        config.headers['x-csrf-token'] = getToken();

        return config;
    },

    checkApiResponse(response: any) {
        const body = response ? response.data : {};
        const config = response ? response.config : undefined;
        // console.log('checkApiResponse', body)
        if (!body) {
            throw new ApiServiceError(config, '没响应数据', 500);
        }
        if (body.code !== 0) {
            // 未登录
            if ((body.code === 106 || body.code === CONVERT_CODE_MAP.get(106)) && !window.__initData.commonData.superCheats) {
                handleLogin(window.location.href);
            }else if((!config.url.split('/').includes('detail') && !config.url.split('/').includes('checkout'))) {
                const shouldThrowError =
                !config.extraCodeList.split('|').includes(body.code + '') &&
                !config.extraCode;

                if (shouldThrowError) {
                    console.log('抛出错误');
                    // 抛出body.data是为了应对诸如验价时，返回的code是非0时，同时data又不为空，且需要取出此时的data
                    throw new ApiPHPError(config, `${body.msg}`, body.code, body.data);
                }
            }
        }
        // 把已经完成的请求从 pending 中移除
        const requestData = generateIdentify(config);
        removePending(requestData);
        return body;
    },
      /**
     * ⚠：仅用于开发环境！
     * 缓存接口响应数据
     * 开发时用于后端接口异常
     * 前端页面依赖接口数据
     **/
    cacheResponse(response: any) {
        const body = response ? response.data : {};
        const config = response ? response.config : undefined;
        const DB_NAME = 'resCache';
        const TABLE_NAME = 'index';
        const DB_VERSION = 1;
        const openRequest = window.indexedDB.open(DB_NAME, DB_VERSION);

        /**
         * 将数据保存到数据库, 有则替换，没有则新建
         * @param {} db -
         */
        function put(db: { transaction: (arg0: string, arg1: string) => { (): any; new(): any; objectStore: { (arg0: string): { (): any; new(): any; put: { (arg0: { path: any; data: any; }): any; new(): any; }; }; new(): any; }; }; }) {
            return new Promise((res, rej) => {
                const result = db
                    .transaction(TABLE_NAME, 'readwrite')
                    .objectStore(TABLE_NAME)
                    .put({
                        path: config.url,
                        data: body,
                    });

                result.onsuccess = function(e: unknown) {
                    console.log(
                        `${config.url} 的请求结果已加入数据库${DB_NAME}的${TABLE_NAME}表中\n`,
                        e
                    );
                    res(e);
                };

                result.onerror = function(e: any) {
                    console.error(
                        `${config.url} 的请求结果未能加入数据库${DB_NAME}的${TABLE_NAME}表中，原因：\n`
                    );
                    console.dir(result.error);
                    rej(e);
                };
            });
        }

        // 从数据库读取数据
        function get(db: { transaction: (arg0: string[], arg1: string) => { (): any; new(): any; objectStore: { (arg0: string): { (): any; new(): any; get: { (arg0: any): any; new(): any; }; }; new(): any; }; }; }) {
            return new Promise(res => {
                const result = db
                    .transaction([TABLE_NAME], 'readwrite')
                    .objectStore(TABLE_NAME)
                    .get(config.url);

                result.onsuccess = (e: any) => {
                    const result = e.target.result;

                    if (result) {
                        alert(
                            `由于${config.url}接口异常，该响应是从IndexedDB中读取的，请查看network进行排错`
                        );
                        res({
                            ...response,
                            status: 200,
                            data: result.data,
                        });
                    }
                    res(response);
                };

                result.onerror = () => {
                    res(response);
                };
            });
        }

        return new Promise(res => {
            openRequest.onsuccess = function(e: any) {
                const db = e.target.result;

                if (body && body.code === 0) {
                    // 正常数据返回则保存到数据库
                    put(db);
                    res(response);
                } else {
                    // 否则从数据库中取出数据
                    res(get(db));
                }
            };

            openRequest.onupgradeneeded = function(e: any) {
                const db = e.target.result;

                // 建表
                if (!db.objectStoreNames.contains(TABLE_NAME)) {
                    const objectStore = db.createObjectStore(TABLE_NAME, {
                        keyPath: 'path',
                    });
                    objectStore.createIndex('path', 'path', { unique: true });
                }
            };
        });
    },
};


const baseHeaders: any = {
    'Content-Type': 'application/json',
};

const $traceId = document.querySelector('meta[name="ea-trace-id"]');
let traceId = '';
if ($traceId) {
    traceId = $traceId.getAttribute('content') || '';
}
if (traceId) {
    baseHeaders['x-trace-id'] = traceId;
}
interface apiService {
    <T>(config: AxiosRequestConfig): Promise<{
        code: number
        data: T
        message: string
    }>
}
//@ts-ignore
const apiService = axios.create({
    baseURL: `${PREFIX_API}/`,
    headers: baseHeaders,
    method: 'GET',
    retry: 0,
    retryDelay: 600,
    useCancel: false,
    extraCode: false,
    extraCodeList: '',
} as RequestConfig) as AxiosInstance & apiService;

apiService.interceptors.request.use(interceptors.enctypeRequestData);
apiService.interceptors.response.use(interceptors.checkApiResponse);

/**
 * 错误源：
 * 1. nodeAPI 代理：ApiServiceError
 * 2. PHP网关代理：ApiPHPError
 * 3. 取消请求：CancelError
 */
class AxiosInterceptorError extends Error {
    code: number;
    config: RequestConfig;
    constructor(config: RequestConfig, message: string, code = 666) {
        super(message);
        this.name = 'AxiosInterceptorError';
        this.code = code;
        this.config = config;
    }
}

class ApiServiceError extends AxiosInterceptorError {
    constructor(config: RequestConfig, message: string, code: number) {
        super(config, message, code);
        this.name = 'ApiServiceError';
        this.message = message;
    }
}

export class ApiPHPError extends AxiosInterceptorError {
    data: any;
    constructor(config: RequestConfig, message: string, code: number, data?: any) {
        super(config, message, code);
        this.name = 'ApiPHPError';
        this.code = code;
        this.message = message;
        this.data = data;
    }
}

export class CancelError extends AxiosInterceptorError {
    constructor(config: RequestConfig, message = '取消', code = 666) {
        super(config, message, code);
        this.name = 'CancelError';
    }
}

/**
 * get请求
 */
//@ts-ignore
const apiGet = <T = any>(url: string, params?: any, options?: RequestConfig) => {
    return apiService<T>({
        url,
        method: 'GET',
        params,
        ...options,
    });
};

/**
 * post请求
 */
const apiPost = <T = any>(url: string, data?: any, options?: RequestConfig) => {
    return apiService<T>({
        url,
        method: 'POST',
        data,
        ...options,
  });
};

/**
 * apiProxy用于请求其他系统的ajax
 */
const apiProxy = axios.create({
    baseURL: `${PREFIX_API}/`,
    timeout: 30000,
    method: 'POST',
    withCredentials: true,
});

/**
 * request
 * @param  {[type]} config [description]
 * @return {[type]}        [description]
 */
apiProxy.interceptors.request.use(
    (    config: any) => {
        return config;
    },
    (    error: any) => {
        Promise.reject(error);
    }
);
/**
 * response
 * @param  {[type]} response [description]
 * @return {[type]}          [description]
 */
apiProxy.interceptors.response.use(
    (    response: { data: any; }) => {
        return Promise.resolve(response.data);
    },
    (    error: any) => {
        return Promise.reject(error);
    }
);

const proxyGet = (url: any, params: any, options: any) => {
    return apiProxy({
        url,
        method: 'GET',
        params,
        ...options,
    });
};

const proxyPost = (url: any, data: any, options: any) => {
    return apiProxy({
        url,
        method: 'POST',
        data,
        ...options,
    });
};
export { apiService, apiGet, apiPost, proxyGet, proxyPost };
