/**
 * 响应中字段code
 * 后端服务 php与java的映射
 */
export default new Map<number, number>([
  [102, 102],
  [106, 109000100],
  [112, 102000040],
  [2005010, 102000301],
  [2005030, 102000302],
  [1001, 102000303],
  [2005020, 102000501],
  [2010010, 102000502],
  [3010009, 103010100],
  [142, 103010101],
  [140, 103010102],
  [144, 103010103],
  [145, 103010104],
  [120, 103015100],
  [114, 103015102],
  [123, 103015106],
  [126, 103015107],
  [30301, 103015110],
  [129, 103015111],
  [118, 103015501],
  [1101, 103015502],
  [12040, 103015503],
  [3010080, 103015504],
  [3010017, 103016000],
  [3010010, 103016001],
  [128, 103017001],
  [113, 104000113],
  [116, 103015101],
  [201, 109000201],
  [1102, 103015103], // 二要素校验不通过 - 05 (这里暂时映射称java身份证类型不匹配的code，主要是按钮逻辑复用，直接写在dialogCodeMap也行的)
]);
